import qs from "qs";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { SupplierTemplateSearchTypes, TemplateStatus } from "enums";
import { useSecurityService } from "services/useSecurityService";
import { PricingTemplateHistoryApiResult, 
         SupplierPricingTemplateHistoryApiQuery, 
         SupplierPricingTemplateHistoryApiResponse, 
         SupplierPricingTemplateHistorySearch, 
         TemplateHistoryApiResponse} from "types";
import { prettyStringify } from "utils";

export function useTemplateHistoryService() {

    const apiUrls = {
        getSupplierPricingHistory: process.env.REACT_APP_SALES_PRICINGIMPORT_SUPPLIER_PRICING_HISTORY_API ?? ""
    };

    const { getAuthHeader } = useSecurityService();

    const getSupplierPricingTemplateHistory = async (searchParams: SupplierPricingTemplateHistorySearch): Promise<PricingTemplateHistoryApiResult> => {
        const apiUrl = apiUrls.getSupplierPricingHistory,
              queryParams = createSupplierPricingHistoryQuery(searchParams),
              config: AxiosRequestConfig = { headers: await getAuthHeader(), 
                                             params: queryParams,
                                             paramsSerializer: (x) => {
                                                 return qs.stringify(x, { arrayFormat: "repeat" })
                                             }};
        try {
            const response = await axios.get(apiUrl, config),
            status = response.data?.StatusCode || response.status,
            dataObj = response.data as TemplateHistoryApiResponse[],
            resultObj = (dataObj ?? []) as SupplierPricingTemplateHistoryApiResponse[];

            if (`${process.env.REACT_APP_DEBUG_CONSOLE_LOGGING}` === "true")
                console.info(`HTTP GET response from "${apiUrl}":\n ${prettyStringify(response)}`);
            
            return { response: resultObj,
                     error: "",
                     statusCode: status } as PricingTemplateHistoryApiResult;
        }
        catch (ex: any) {
            const error = ex as AxiosError,
                  status = error.response?.status ?? 0;
            console.error(error.message);
            return { response: [], 
                     error: error.message ? error.message 
                                          : `Error fetching data from template history API` + 
                                            `${error.response?.statusText ? `: ${error.response.statusText}` 
                                                                          : ""}`,
                     statusCode: status } as PricingTemplateHistoryApiResult;
        }
    };

    const createSupplierPricingHistoryQuery = (searchParams: SupplierPricingTemplateHistorySearch): SupplierPricingTemplateHistoryApiQuery => {
        const terms = searchParams.searchTerms.map(x => x.trim()),
              status = searchParams.templateStatus?.toString() ?? TemplateStatus.Any.toString();

        switch (searchParams.searchType) {
            case SupplierTemplateSearchTypes.TemplateId:
                return {
                    SearchBy: SupplierTemplateSearchTypes.TemplateId.toString(),
                    TemplateId: terms[0]
                };
            case SupplierTemplateSearchTypes.VendorName:
                return {
                    SearchBy: SupplierTemplateSearchTypes.VendorName.toString(),
                    Status: status,
                    VendorName: terms
                };
            case SupplierTemplateSearchTypes.VendorNumber:
                return {
                    SearchBy: SupplierTemplateSearchTypes.VendorNumber.toString(),
                    Status: status,
                    VendorNumber: terms
                };
            case SupplierTemplateSearchTypes.ProductLineName:
                return {
                    SearchBy: SupplierTemplateSearchTypes.ProductLineName.toString(),
                    Status: status,
                    ProductLineName: terms
                };
            case SupplierTemplateSearchTypes.ProductLineNumber:
                return {
                    SearchBy: SupplierTemplateSearchTypes.ProductLineNumber.toString(),
                    Status: status,
                    ProductLineNumber: terms
                };
            default:
                throw Error(`useTemplateHistoryService.createSupplierPricingHistoryQuery: Search ` +
                            `type "${searchParams.searchType}" query constructor is not implemented, ` + 
                            `this is a bug and should be reported.`);
        }
    }

    const testingExports = {
        createSupplierPricingHistoryQuery
    };

    return {
        testingExports,
        getSupplierPricingTemplateHistory
    };
}
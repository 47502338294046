import { TemplateHistoryApiResponse } from "./TemplateHistoryApiResult"

export type SupplierPricingTemplateHistoryApiResponse = 
    TemplateHistoryApiResponse & 
    SupplierPricingTemplateHistoryMetadata;

export type SupplierPricingTemplateHistoryMetadata = {
    MissingItems: SupplierPricingTemplateHistoryMissingItems[] | null
    Vendors: VendorNameNumber[] | null,
    ProductLines: ProductLineNameNumber[] | null
}

export type VendorNameNumber = {
    VendorName: string | null,
    VendorNumber: string | null
}

export type ProductLineNameNumber = {
    ProductLineName: string | null,
    ProductLineNumber: string | null
}

export type SupplierPricingTemplateHistoryMissingItems = {
    ProductLine: string | null,
    ItemNumbers: string[] | null
}

//Representation of the column header rows in the excel for the supplier list pricing template
export const SupplierPricingExcelTemplateHeaderRows_AoA = [
    [
        "PRICE",
        "VENDOR#",
        "VENDORNAME",
        "PRICEBEGIN",
        "PRICEEND",
        "PRICEDATETYPE",
        "REGION",
        "SKU/",
        "MASTERPACK",
        "PRODUCTDESCRIPTION",
        "PRICEUOM",
        "PRICE",
        "PRICE",
        "PRICEBRKT1",
        "PRICE",
        "PRICE",
        "PRICEBRKT2",
        "PRICE",
        "PRICE",
        "PRICEBRKT3",
        "PRICE",
        "PRICE",
        "PRICEBRKT4",
        "PRICE",
        "PRICE",
        "PRICEBRKT5",
        "PRICE",
        "PRICE",
        "PRICEBRKT6",
        "PRICE",
        "PRICE",
        "PRICEBRKT7",
        "PRICE",
        "PRICE",
        "PRICEBRKT8",
        "PRICE",
        "PRICE",
        "PRICEBRKT9",
        "PRICE",
        "PRICE",
        "PRICEBRKT10",
        "PRODUCT"
    ],
    [
        "LIST",
        "",
        "",
        "EFFECTIVEDATE",
        "EFFECTIVEDATE",
        "",
        "COVERED",
        "MODEL#",
        "14DIGITGTIN(UPC)",
        "",
        "",
        "BRKT1",
        "BRKT1",
        "QTYDESCRIPTION",
        "BRKT2",
        "BRKT2",
        "QTYDESCRIPTION",
        "BRKT3",
        "BRKT3",
        "QTYDESCRIPTION",
        "BRKT4",
        "BRKT4",
        "QTYDESCRIPTION",
        "BRKT5",
        "BRKT5",
        "QTYDESCRIPTION",
        "BRKT6",
        "BRKT6",
        "QTYDESCRIPTION",
        "BRKT7",
        "BRKT7",
        "QTYDESCRIPTION",
        "BRKT8",
        "BRKT8",
        "QTYDESCRIPTION",
        "BRKT9",
        "BRKT9",
        "QTYDESCRIPTION",
        "BRKT10",
        "BRKT10",
        "QTYDESCRIPTION",
        "LINE"
    ],
    [
        "NUMBER",
        "",
        "",
        "YYYYMMDD",
        "YYYYMMDD",
        "",
        "(ZONE/AREA)",
        "",
        "",
        "",
        "",
        "ID",
        "PRICE",
        "",
        "ID",
        "PRICE",
        "",
        "ID",
        "PRICE",
        "",
        "ID",
        "PRICE",
        "",
        "ID",
        "PRICE",
        "",
        "ID",
        "PRICE",
        "",
        "ID",
        "PRICE",
        "",
        "ID",
        "PRICE",
        "",
        "ID",
        "PRICE",
        "",
        "ID",
        "PRICE",
        "",
        ""
    ]
  ];